<template>
  <!-- default container -->
  <div>

  </div>
  <!-- end default container -->
</template>

<script>


export default {
  name: 'Forward',
  components: {

  },

  created() {
    window.location.href = "https://www.stellantis.com/en";
  },

  data() {
    return {
      
    };
  },

  methods: {

    setPageTitle() {
      document.title = 'Forwarding...';
    },

  },

  computed: {

  },

};
</script>